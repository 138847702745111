<template>
    <div class="channel__page">
    <div class="clipPage">
    <div class="clip mt-3">
    <vue-plyr ref="plyr" :options="options" poster="https://assets.brimecdn.com/loading_clip.gif">
    <video id="video" :src="clipSource" autoplay playsinline></video>
    </vue-plyr>
    </div>
    <div class="channelData">
    <router-link :to="`/${channelData.slug}`">
      <b-avatar
        v-if="clip.channel_xid"
        size="70"
        :src="`https://assets.brimecdn.com/cdn-cgi/image/width=80,quality=100/users/${channelData.owner.xid}/avatar`"
        variant="light-primary"
        class="badge-minimal hide-on-mobile"
      />
    </router-link>
    <span style="margin-left: 10px;">
      <span
        style="
                vertical-align: top;
                color: white;
                font-size: 16px;
              "
        class="channelName"
        id="channelName"
      ><!---
        <img
          v-if="channelData.channel.is_verified"
          src="https://beta.brimelive.com/brime_verified.png"
          style="padding-left: 5px"
        >-->
        {{ channelData.display_name }} | <span style="color: #999999; font-size: 14px;">Clipped by <router-link :to="`/${clip.clipper.display_name}`">{{clip.clipper.display_name}}</router-link></span></span>
      <span style="vertical-align: top; opacity: 0.5; line-height:25px;">
        </span>
      <span
        class="streamCategory"
      >
        <router-link :to="`/category/${categoryData.slug}`">{{categoryData.name}}</router-link>
        &bull;
        <span class="streamTitle" v-if="clip.title">
         {{clip.title}}
         <div class="date">{{date}}
            </div>
        </span>
      </span>
    </span>
    <br>
    <twitter v-b-tooltip.hover.bottom="'share to twitter'" scale="2" :url="`https://brime.tv/clip/${clip.xid}`"/>
    <reddit v-b-tooltip.hover.bottom="'share to reddit'" scale="2" :url="`https://brime.tv/clip/${clip.xid}`"/>
    <facebook v-b-tooltip.hover.bottom="'share to facebook'" scale="2" :url="`https://brime.tv/clip/${clip.xid}`"/>
    </div>
    </div>
    </div>
</template>

<script>
import {
  BAvatar, BButton, VBTooltip
} from 'bootstrap-vue'
import { Twitter, Facebook, Reddit } from 'vue-socialmedia-share';
const playerjs = require("player.js")
const receiver = new playerjs.Receiver();

export default {
props: ['xid'],
components: {
  BAvatar,
  BButton, 
  Twitter,
  Facebook,
  Reddit,
},
directives: {
    'b-tooltip': VBTooltip,
  },
data() {
    return {
      date: null,
      categoryData: null,
        options: {
    controls: [
    //'restart', // Restart playback
    'rewind', // Rewind by the seek time (default 10 seconds)
    'play', // Play/pause playback
    'fast-forward', // Fast forward by the seek time (default 10 seconds)
    'progress', // The progress bar and scrubber for playback and buffering
    'current-time', // The current time of playback
    'duration', // The full duration of the media
    'mute', // Toggle mute
    'volume', // Volume control
    'captions', // Toggle captions
    'settings', // Settings menu
    //'pip', // Picture-in-picture (currently Safari only)
    'airplay', // Airplay (currently Safari only)
    'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
    'fullscreen', // Toggle fullscreen
], urls:{download: ''}},
        clip: { broadcast: {category: ''},clipper: {xid: '', display_name:''}, channel_xid: ''},
        clipSource: null,
        isChannelOwner: false,
        channelData: {owner: { xid: ''}},
    }
},
mounted() {
    document.body.classList.add('channel__page')
},
async created() {
    this.clip = await this.clipLookup(this.xid)
    const categoryData= await this.categoryLookupByXID(this.clip.broadcast.category)
    this.categoryData = categoryData
    this.channelData = await this.channelLookupByXID(this.clip.channel_xid)
    window.document.title = this.clip.title + ' - Brime'
    this.$refs.plyr.player.download = `https://clips.brimecdn.com/${this.clip.channel_xid}/clips/${this.clip.xid}.mp4`,
    this.clipSource = `https://clips.brimecdn.com/${this.channelData.xid}/clips/${this.clip.xid}.mp4`
    var utcTimestamp = this.clip.start_time * 1000
    var d = new Date(0);
    var date = new Date(utcTimestamp); 
    this.date = date.toLocaleString( {hour12:true } ).replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3")
},
mounted(){
  var video = document.getElementById("video");
const adapter = playerjs.HTML5Adapter(video);
// Start accepting events
adapter.ready();
},
beforeDestroy() {
    elem.speed = 1
    document.body.classList.remove('channel__page')
},
}
</script>

<style>
.tooltip {
    margin-top: 12px;
}
.fa-icon:hover{
    color: #ffffff;
    cursor: pointer;
    margin-right: 5px;
}
.fa-icon{
    color: #7367f0;
    cursor: pointer;
    margin-right: 5px;
}
.date{
    margin-top: 3px;
}
.clipPage{
margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}
.actionButtons {
  padding-top: 0.25rem;
  right: calc(21%) !important;
  }
.channelData {
    display: block;
    font-size: 14px !important;
    margin-top: 10px !important;
    margin-left: 1rem !important;
  }
  .streamCategory {
    display: block;
  }
  .streamCategory {
  margin-top: -45px;
  margin-left: 85px;
  font-size: 14px;
}
.clip {
    max-width: 1200px;
}
.plyr {
  --plyr-color-main: linear-gradient(90deg, #833ab4, #fd1d1d) !important;
  font-family: 'Roboto', sans-serif;
}
body.channel__page .app-content {
  padding-top: 62.3px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
  @media all and (max-width: 767px) {
      .clipPage{
top: 240px !important;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}
  body.channel__page .app-content {
  padding-top: 62.3px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
  }
</style>